import {defineStore} from "pinia";
import {useStoryblokApi} from "@storyblok/vue";
import Page from "@/models/Page";
import Stage from "@/models/Stage";
import Story from "@/models/Story";
import Artist from "@/models/Artist";
import Speaker from "@/models/Speaker";
import Timeslot from "@/models/Timeslot";
import News from "@/models/News";
import Settings from "@/models/Settings";
import {DateTime} from "ts-luxon";
import {allDoc} from "@/plugins/firestore";
import {onSnapshot} from "firebase/firestore";

export const useStore = defineStore('store', {
    state: () => ({
        section: 'festival' as 'conference' | 'festival',
        favSorting: 'time' as 'title' | 'time',
        platform: null as string | null,
        splashHidden: false,
        disableMenu: false,
        leftOpened: false,
        rightOpened: false,
        modalOpened: 0,

        stories: [] as any[],
        storiesUpdated: null as null | number,

        fcmToken: null as null | string,
        likes: [] as {
            uuid: string,
            type?: 'artist' | 'speaker' | 'timeslot'
        }[],
        now: DateTime.now(),
        config: {} as Settings,
        blurhash: [] as {
            uuid: string,
            hash: string
            url: string
        }[],
    }),
    getters: {
        getStoryByUuid: (state) => (uuid: string) => {
            return state.stories.find((story: any) => story.uuid === uuid) || null
        },
        getStoryBySlug: (state) => (slug: string) => {
            return state.stories.find((story: any) => story.slug === slug) || null
        },
        getLikeByUuid: (state) => (uuid: string) => {
            return state.likes.find((story: any) => story.uuid === uuid) || null
        },
        getHashByUrl: (state) => (url: string) => {
            return state.blurhash.find((h) => h.url === url) || null
        },
        getHashByUuid: (state) => (uuid: string) => {
            return state.blurhash.find((h) => h.uuid === uuid) || null
        }
    },
    actions: {
        async loadStories() {
            // Poor mans cron
            const now = DateTime.now()

            if(this.storiesUpdated) {
                const cacheTime = DateTime.fromSeconds(this.storiesUpdated)
                    .plus({minutes: 5})

                if(now < cacheTime)
                    return
            }


            const perPage = 100;
            const storyblokApi = useStoryblokApi();
            const fetchStories = async (page: number) => {
                const { data, total } = await storyblokApi.get("cdn/stories", {
                    version: "draft",
                    per_page: perPage,
                    page: page,
                });
                return {
                    stories: hydrateStories(data.stories),
                    total,
                };
            };

            const getAllStories = async () => {
                let page = 1;
                let total = 1;
                const stories = [];

                while (stories.length < total) {
                    const response = await fetchStories(page);

                    stories.push(...response.stories);
                    total = response.total;
                    page++;

                    console.log(`Loaded ${stories.length} of ${total} stories...`)
                }

                return stories;
            };

            this.stories = await getAllStories();

            this.config = this.stories
                .filter((story: any) => story.type === "settings")[0] || {}
            this.storiesUpdated = now.toUnixInteger()
            blurHash(this.stories, this)
        }
    },
    persist: {
        key: 'store',
        afterRestore: (ctx) => {
            ctx.store.now = DateTime.now()
            ctx.store.splashHidden = false
            ctx.store.stories = hydrateStories(ctx.store.stories)
            ctx.store.config = ctx.store.stories
                .filter((story: any) => story.type === "settings")[0] || {}
        }
    }
})

const blurHash = async (stories: any[], store: any) => {

    return new Promise(function(res, rej) {

        const thumbs = stories.filter(s => s.thumb).map(s => ({
            uuid: s.thumb.split("/")[6],
            url: s.thumb,
            hash: null
        }))

        // console.log(thumbs)

        onSnapshot(allDoc, (doc) => {
            const data = doc.data()
            const content = JSON.parse(data?.content)
            store.blurhash = content
            console.log("Hashes loaded...")
            res(content)
        })
    })
}

const hydrateStories = (data: any) => {
    return data.map((story: any) => {
        switch (story.content.component) {
            case "stage":
                return new Stage(story)
            case "news":
                return new News(story)
            case "artist":
                return new Artist(story)
            case "speaker":
                return new Speaker(story)
            case "timeslot":
                return new Timeslot(story)
            case "page":
                return new Page(story)
            case "settings":
                return new Settings(story)
            default:
                return new Story(story)
        }
    })
}
