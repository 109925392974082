import BaseModel from "@/models/BaseModel";
import {DateTime} from "ts-luxon";

export default class Story extends BaseModel<any> {
  public declare id: string
  public declare uuid: string
  public declare content: Record<string, any>
  public declare full_slug: string
  public declare created_at: string
  public declare published_at: string
  public declare tag_list: string[]
  public declare is_startpage: boolean

  public get title() : string {
    return this.content.title
  }
  public get type() : string {
    return this.content.component
  }
  public get tags() : string[] {
    return this.tag_list
  }

  public get createdFormatted() : DateTime {
    return DateTime.fromISO(this.created_at)
  }

  public get publishedFormatted() : DateTime {
    return DateTime.fromISO(this.published_at)
  }
}
