import Story from "@/models/Story";
import {Image} from "@/models/Image";
import {useContent} from "@/composables/content";
import SpeakerDetail from "@/modals/SpeakerDetail.vue";
import Timeslot from "@/models/Timeslot";
import {useStore} from "@/store";
import {computed} from "vue";

export default class Speaker extends Story {

  public get image(): Image {
    return this.content?.image ? this.content.image : null
  }
  public get thumb(): string {
    return this.image ? `${this.image.filename}/m/300x300/smart` : ''
  }
  public get picture(): string {
    return this.image ? `${this.image.filename}/m/1200x1200/smart` : ''
  }
  public get perex() {
    return this.content.perex
  }
  public get blocks() {
    return this.content.content
  }
  public get socials() {
    return ['spotify', 'facebook', 'youtube', 'instagram'].map(key => ({
      icon: key,
      link: this.content[key] || null
    })).filter(i => i.link)
  }
  public get timeslot() : Timeslot {
    return useContent().timeslots.value.find(t => t.content.speakers.includes(this.uuid))!
  }

  public get day() {
    return this.timeslot ? this.timeslot.day : null
  }

  public get isLiked() {
    const store = useStore()
    return computed(() => store.likes.find(l => l.uuid === this.uuid))
  }

  public get timeStart() {
    return this.timeslot ? this.timeslot.timeStart : null
  }
  public get timeEnd() {
    return this.timeslot ? this.timeslot.timeEnd : null
  }

  public get timeFormatted() {
    return this.timeStart && this.timeEnd ? `${this.timeStart.toFormat('dd.MM. HH:mm')} - ${this.timeEnd.toFormat('HH:mm')}` : ''
  }

  public get timeFormattedShort() {
    return this.timeStart && this.timeEnd ? `${this.timeStart.toFormat('HH:mm')} - ${this.timeEnd.toFormat('HH:mm')}` : ''
  }

  public get view() {
    return SpeakerDetail
  }
}
