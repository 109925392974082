import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';

import {useStore} from "@/store";

const registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
}

export const notifications = async () => {
    try {
        const store = useStore()
        const info = await Device.getInfo();
        if(info.platform === 'web') {
            return
        }

        await registerNotifications()
        const {token} = await FCM.getToken()
        store.fcmToken = token

        console.log(store.fcmToken)
        await FCM.setAutoInit({ enabled: true });

        // there is bug in FCM, we need to wait to subscribe
        setTimeout(async () => {
            await FCM.subscribeTo({ topic: 'all' });
        }, 5000)
    } catch (e) {
        console.log(e)
    }
}



